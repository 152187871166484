import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import locale from "../locale/en"
import SEO from "../components/seo"
import FaqGrid from "../components/faq-grid"
import Cta from "../components/cta"
import BackLink from "../components/back-link"

const { home } = locale

const IndexPage = () => (
  <Layout>
    <SEO title={home.title} />
    <BackLink url="/" text="Back to PPE Access Program Page" />
    <div className="prose max-w-none mb-10">
      <h1>{home.title}</h1>
      <h2>Estimate your PPE Needs</h2>
      <p className="italic">
        The information provided here has been compiled from public websites for
        the Center for Disease Control, Occupational Safety and Health
        Administration, and American College of Occupational and Environmental
        Medicine. This information is subject to change. You must use the source
        links at the bottom of the page for their most current information,
        updates, and details. And you should consider engaging with your Local
        Department of Health for relevant local guidance.{" "}
      </p>
    </div>

    <Cta>
      <div className="prose max-w-full mb-8">
        <h2>{home.calculator_title}</h2>

        <p>{home.calculator_description}</p>
      </div>

      <Link to="/calculator/" className="btn btn-primary inline-block">
        {home.calculator_cta}
      </Link>

      <div className="text-sm text-gray-500 mt-2">
        Note: Please note that this planning tool provides generale guidance
        only. You are solely responsible for evaluating your business’s unique
        PPE needs.{" "}
      </div>
    </Cta>

    <div className="prose max-w-none mb-5" id="faq">
      <h2>Read PPE guidance for small businesses</h2>
      <p>
        Understand how different types of PPE and safeguarding equipment should
        be used by non-medical businesses and what to look for before making a
        purchase.
      </p>
    </div>

    <FaqGrid />
  </Layout>
)

export default IndexPage
