import React from "react"

const Cta = ({ children, className = "" }) => (
  <div
    className={`bg-backgroundHighlight rounded-md p-10 mb-10 mx-auto max-w-3xl text-center ${className}`}
  >
    {children}
  </div>
)

export default Cta
